/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"

function SEO({ description, lang, meta, title, image, id, price, availability, link }) {
  var { pathname } = useLocation()
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  let isPropertyPage = false;
  
  if (pathname == "/home/" || pathname == "/home") pathname = "/";

  if(pathname.includes("property-for-sale" || "property-to-rent")) {
    isPropertyPage = true;
  }

  var curentUrl = ""
  curentUrl = process.env.GATSBY_SITE_URL + pathname
  if (!curentUrl.endsWith("/")) curentUrl = curentUrl + "/"

  //remove multiple slashes
  curentUrl = curentUrl.replace(/([^:]\/)\/+/g, "$1")
  //set lowercase url
  curentUrl = curentUrl.toLowerCase();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          href: curentUrl,
          rel: "canonical",
        },
      ]}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:image:secure_url`,
          content: image,
        },
        {
          property: `og:image:type`,
          content: `image/jpeg`,
        },
        {
          property: `og:image:width`,
          content: `400`,
        },
        {
          property: `og:image:height`,
          content: `300`,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `facebook-domain-verification`,
          content: ``,
        },
      ]

      // Add additional metadata only if it's a property details page
      .concat(isPropertyPage ? [
        {
          property: `product:retailer_item_id`,
          content: id,
        },
        {
          property: `product:price:amount`,
          content: price,
        },
        {
          property: `product:price:currency`,
          content: 'GBP',
        },
        {
          property: `product:link`,
          content: encodeURIComponent(link),
        },
        {
          property: `product:availability`,
          content: `in stock`
        },
      ] : [])
      .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
}

export default SEO
